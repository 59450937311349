import { render, staticRenderFns } from "./recruit.vue?vue&type=template&id=40bdbbe0&scoped=true&"
import script from "./recruit.vue?vue&type=script&lang=js&"
export * from "./recruit.vue?vue&type=script&lang=js&"
import style0 from "./recruit.vue?vue&type=style&index=0&id=40bdbbe0&lang=scss&scoped=true&"
import style1 from "./recruit.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40bdbbe0",
  null
  
)

export default component.exports