<template>
  <div class="main">
    <div class="recuritbanner">
      <img src="https://water-1302249616.cos.ap-nanjing.myqcloud.com//2021-12-07/d9b9c4133d6d4137be2551922c7ba55a-banner_solution.jpg" alt style="width:100%">
      <div class="recruitment">社会招聘职位</div>
    </div>
    <el-card class="box-card card">
      <div v-for="item in tableData" :key="item">
        <router-link :to="'/recucontent?id=' + item.id">
          <div class="cardcontent">
            <div>
              <div class="position">
                <div class="emergency">急</div>
                <div class="gongzhong">{{ item.jobTitle }}</div>
              </div>
              <div class="salary">
                <div class="full-time">全职</div>
                <div class="qian">{{ item.salary }}</div>
              </div>
              <div class="city">{{ item.officeLocation }}</div>
            </div>
            <div class="icon">
              <i class="el-icon-right" />
            </div>
          </div>
        </router-link>
      </div>
    </el-card>
  </div>
</template>

<script>
// import { getSubmit } from '@/api/admin/recruit'
import { getUsermanagement } from '@/api/admin/homePage'
export default {
  data() {
    return {
      tableData: [],
      query: {
        page: 1,
        limit: 20,
        name: ''
      },
      detail: {
        jobRequirements: '',
        responsibilities: ''
      }
    }
  },
  created() {
    this.gettableData()
  },
  methods: {
    // 招聘数据
    gettableData() {
      getUsermanagement(this.query)
        .then(res => {
          console.log('getUsermanagement', res)
          this.tableData = res.data.items
        })
    },
    editMeta(row) {
      this.detail = row
      this.dialogTableVisible = true
    }
  }

}

</script>
<style lang="scss" scoped>
.main {
  margin: 0 auto;
  .recuritbanner {
    .recruitment {
      width: 100%;
      font-size: 34px;
      color: #333;
      font-weight: bold;
      margin-top: 50px;
      margin-bottom: 50px;
      text-align: center;
    }
  }
  .card {
    .cardcontent {
      display: flex;
      padding: 37px;
      border-bottom: 1px solid rgb(238, 238, 238);
      .icon {
          margin-left: auto;
        font-size: 21px;
        margin-top: 25px;
      }
      .icon:hover {
        color: red;
      }
    }
    .cardcontent:hover {
      background-color: rgb(249, 249, 250);
    }
    .position {
      display: flex;
      .gongzhong {
        margin-left: 2px;
        line-height: 23px;
      }
      .emergency {
        background-color: red;
        width: 23px;
        height: 23px;
        line-height: 21px;
        color: white;
        text-align: center;
      }
    }
    .salary {
      display: flex;
      margin-top: 10px;
      .full-time {
        color: rgb(168, 170, 174);
      }
      .qian {
        color: rgb(197, 150, 84);
        margin-left: 10px;
      }
    }
    .city {
      color: rgb(153, 157, 165);
      margin-top: 10px;
    }
  }
  ::v-deep {
    .el-card__body {
      padding: 0px 0px;
    }
  }
}
</style>
<style lang="scss">
.main {
  .box-card {
    margin: 27px 305px;
    border-radius: 5px;
    background: white;
    -webkit-box-shadow: 0px 2px 12px rgb(0 0 0 / 10%);
    box-shadow: 0px 2px 12px rgb(0 0 0 / 10%);
    display: -webkit-box;
    display: -ms-flexbox;
    text-align: left;
  }
}
</style>
